import React from "react";
import { Col, Container, Row } from "reactstrap";
import { CopyRight, FooterMenu, FooterWrapper } from "./footerStyles";
import logoWhite from "../../assets/images/COPA_Logo_white.png";
import { Facebook, Linkedin, Mail, Phone, Smartphone, Twitter, Youtube } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faLinkedin,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer>
      <FooterWrapper className="pt-6 pb-5 ">
        <div className="custom-container">
          <Row className="inner-wrapper">
            <Col className="column-1 text-lg-center">
              <img
                src={logoWhite}
                alt="logowhite"
                width="190px"
                style={{ maxWidth: "190px", width: "100%" }}
                className="mb-2"
              />
              <p className="mt-3 text-lg-center">
                1225 New York Ave. NW, Suite 600 Washington, D.C. 20005
              </p>
              <p className="text-lg-center ">
                <Smartphone size="14px" className="mr-1" />
                <a href="tel:(202) 729-8147">(202) 729-8147</a>
              </p>
              <p className="text-lg-center">
                <Mail size="14px" className="mr-1" />
                <a href="mailto:info@coacancer.org">info@coacancer.org</a>
              </p>
              <div className="social-media d-flex align-items-center justify-content-lg-center text-white">
                <a href="https://www.facebook.com/CommunityOncologyAlliance" target={"_blank"}>
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/OncologyCOA" target={"_blank"}>
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.youtube.com/user/OncologyCOA" target={"_blank"}>
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a
                  href="https://www.linkedin.com/company/community-oncology-alliance-washington-dc/"
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} size="1.7x" />
                </a>
              </div>
            </Col>
            <Col className="column-2">
              <h5 className=" text-white">RESOURCES</h5>
              <FooterMenu className="footer-menus">
                <a href="https://laws.mycoa.io/">Policy Tracker & Maps</a>
                <a href="https://coacan.org/patient-feedback-tools/" target={"_self"}>
                  Patient Feedback Tools
                </a>
                <a href="https://coanalyzer.net/" target={"_self"}>
                  Pharmacy & Practice Benchmarking Software
                </a>

                <a
                  href="/resources/pbm-tools/resources-for-pbm-and-insurer-abuses"
                  target={"_self"}
                >
                  Addressing PBM & Insurer Abuses
                </a>
              </FooterMenu>
            </Col>

            <Col className="column-3">
              <h5 className=" text-white">INITIATIVES</h5>
              <FooterMenu className="footer-menus">
                <a href="https://www.coacan.org/" target={"_self"}>
                  COA Administrators’ Network (CAN)
                </a>

                <a href="https://www.coaadvocacy.org/" target={"_self"}>
                  COA Patient Advocacy Network (CPAN)
                </a>
                <a href="https://communityoncology.org/eom/" target={"_self"}>
                  Enhancing Oncology Model Support Network (EOM)
                </a>

                <a href="https://www.medicalhomeoncology.org/" target={"_self"}>
                  Oncology Medical Home (OMH)
                </a>
                <a href="https://communityoncology.org/fellows/" target={"_self"}>
                  COA Fellows Initiative
                </a>
              </FooterMenu>
            </Col>

            <Col className="column-4">
              <h5 className="text-white">EVENTS</h5>
              <FooterMenu className="footer-menus">
                <a href="https://coaconference.com/" target={"_self"}>
                  Community Oncology Conference
                </a>
                <a href="https://payerexchangesummit.com/" target={"_self"}>
                  Payer Exchange Summit
                </a>
                <a
                  href="https://mycoa.communityoncology.org/events/state-of-the-community-oncology-briefings/"
                  target={"_self"}
                >
                  State of the Community Oncology Briefings
                </a>
                <a href="https://coaadvocacy.org/advocacy-chats/" target={"_self"}>
                  Advocacy Chats
                </a>
                <a href="https://coaadvocacysummit.com/" target={"_self"}>
                  Advocacy Summits
                </a>
              </FooterMenu>
            </Col>

            <Col className="column-5">
              <h5 className=" text-white">GET INVOLVED</h5>
              <FooterMenu className="footer-menus">
                <a href="https://mycoa.io/" target={"_self"}>
                  Join COPA
                </a>
                <a href="https://communityoncology.org/membership/" target={"_self"}>
                  Membership Information
                </a>
                <a href="https://coaadvocacy.org/become-a-cpan-advocate/" target={"_self"}>
                  Become an Advocate
                </a>
                <a href="https://coapharmacy.com/subscribe-to-updates/" target={"_self"}>
                  Subscribe to Updates
                </a>
              </FooterMenu>
            </Col>
          </Row>
        </div>
      </FooterWrapper>

      <CopyRight className="px-3 text-center text-md-right">
        <>
          © Copyright Community Oncology Alliance
          <span className="mx-2 ">|</span> All Rights Reserved
          <span className="mx-2 ">|</span>
          <a href="https://communityoncology.org/terms-and-conditions/" target={"_self"}>
            Terms & Conditions
          </a>
          <span className="mx-2 text-link">|</span>
          <a href="https://communityoncology.org/privacy-policy/" target={"_self"}>
            Privacy Policy
          </a>
        </>
      </CopyRight>
    </footer>
  );
};

export default Footer;
