import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
const BrownWhite = () => {
const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
const [items, setItems] = useState(null);
const domain = "login.mycoa.io";
const { slug } = useParams();
const baseURL = "https://mycoa.io/api";

useEffect(() => {
  const getUserMetadata = async () => {
    try {
      const accessToken1 = await getAccessTokenSilently({
        audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();
      return user_metadata;
    } catch (e) {
      console.log(e.message);
    }
  };
  getUserMetadata().then(function (result) {
    console.info("result", result);
     document.querySelector("#authCan").classList.add("hide");
     document.querySelector("#noAuthCan").classList.add("hide");
    //if (isAuthenticated && result && result.COPA === "COPA") {
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1) {
      document.querySelector("#authCan").classList.remove("hide");
      document.querySelector("#noAuthCan").classList.add("hide");
    } else {
      document.querySelector("#authCan").classList.add("hide");
      document.querySelector("#noAuthCan").classList.remove("hide");
    }
  });
}, [getAccessTokenSilently, user?.sub]);

  return (
    <div className="page-wrapper my-6">
      {isAuthenticated ? (
        <div>
      <div id="noAuthCan" className="hide">
        <Container>
        <p>
        When our members take an active role as an advocate for patient rights
          and freedom of choice, you are doing your part to make sure that
          issues facing your profession and your economic future are addressed.
          COPA’s goal is to build an effective and dynamic advocacy network that
          will translate into positive results for our members and their
          patients. COPA remains committed to grassroot advocacy to influence
          the public policy debate and enactment of legislation to prohibit
          practices that are harmful to patients.</p>
          <p>
          A growing – and extremely concerning – trend that has emerged is the
          concept of mandatory “white bagging” of oncology medications that are
          administered in-office by community oncology practices. In seeming
          unison, several health insurance companies (who have integrated PBMs
          and specialty pharmacies) have begun to mandate that certain IV drugs
          that were previously purchased by community oncology practices and
          administered in-office to patients, and now requiring that they be
          filled by the PBM-owned specialty pharmacy. These are drugs that
          historically have been administered in-office by community oncology
          practices and billed to patients’ medical benefit (as opposed to their
          pharmacy benefit). In essence, these payers (which include Anthem Blue
          Cross of California, Blue Cross Blue Shield of Tennessee and Cigna)
          have mandated that cancer patients receive their chemotherapy through
          white or brown bagging, to be supplied by the affiliated specialty
          pharmacy.
        </p>
          </Container>
        </div>
      <div id="authCan" className="hide">
      <Container>
        <p>
          When our members take an active role as an advocate for patient rights
          and freedom of choice, you are doing your part to make sure that
          issues facing your profession and your economic future are addressed.
          COPA’s goal is to build an effective and dynamic advocacy network that
          will translate into positive results for our members and their
          patients. COPA remains committed to grassroot advocacy to influence
          the public policy debate and enactment of legislation to prohibit
          practices that are harmful to patients.
        </p>
        <p>
          A growing – and extremely concerning – trend that has emerged is the
          concept of mandatory “white bagging” of oncology medications that are
          administered in-office by community oncology practices. In seeming
          unison, several health insurance companies (who have integrated PBMs
          and specialty pharmacies) have begun to mandate that certain IV drugs
          that were previously purchased by community oncology practices and
          administered in-office to patients, and now requiring that they be
          filled by the PBM-owned specialty pharmacy. These are drugs that
          historically have been administered in-office by community oncology
          practices and billed to patients’ medical benefit (as opposed to their
          pharmacy benefit). In essence, these payers (which include Anthem Blue
          Cross of California, Blue Cross Blue Shield of Tennessee and Cigna)
          have mandated that cancer patients receive their chemotherapy through
          white or brown bagging, to be supplied by the affiliated specialty
          pharmacy.
        </p>
        <p>
          “White bagging” occurs where a physician writes an order for
          particular medication for an in-office procedure, and rather than
          being sourced from the physician’s medication inventory, a separate
          specialty pharmacy fills a prescription for the patient, and delivers
          the drug directly to the prescriber or clinic who retains the
          medication until the patient arrives at their office for
          administration. Likewise, “brown bagging,” which is less common,
          involves a similar concept, except that instead of causing the
          prescription to be delivered directly to the community oncology
          practice, the specialty pharmacy dispenses the medication to the
          patient him or herself, who then brings their own medications into
          their physicians’ offices for administration in those settings. Each
          of these scenarios present immense concerns for patients, payers and
          providers alike.
        </p>
        <p>
          In collaboration with Frier Levitt, we have developed a template
          letter that can be used as part of our grassroots efforts to combat
          this trend.&nbsp; The template letter (
          <a
            href="https://coapharmacy2.com/wp-content/uploads/2022/05/COPA-COA_White-bagging-legal-letter.docx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>Download HERE</b>
          </a>
          ) can be used to respond to these instances where insurance companies
          and/or PBMs are requiring your practice to accept white bagged
          medications.&nbsp; You can adopt the letter to fit your locality and
          circumstances. The message is clear and concise and presents the case
          against white/brown bagging using the strongest arguments in a way
          that is both factual and convincing. The letter can be sent in
          response to any Health Plan mandate for your practice to accept
          medications via white/brown bagging. The letter indicates the Practice
          will not accept brown-bagging under&nbsp;
          <strong>
            <em>any</em>
          </strong>
          &nbsp;circumstances. The letter states that the practice will not
          consider accepting white-bagged medications until both the Insurance
          Company and its affiliated specialty pharmacy satisfactorily respond
          to several pointed questions regarding the legality, safety and
          appropriateness of the white bagging mandate.&nbsp; The letter further
          demands that both the Insurance Company and its affiliated specialty
          pharmacy sign and return an Assumption of Liability and
          Indemnification Agreement.
        </p>
        <p>
          We will continue to deliver vital leadership to help our physician
          practices, advocating for changes that benefit you and your patients.
          Our efforts are most effective when combined with an overwhelming
          response from our members. While it is each practice’s decision on
          whether to ultimately accept white- bagged products altogether*, this
          letter is designed primarily to provide assurances for the practice if
          they ultimately accept white-bagged products, and in so doing, may
          also dissuade the Insurance Company from mandating that the practice
          accept white-bagged products (as a result of the high standards needed
          to be met for quality).
        </p>
        <p>
          COPA invites all members adversely effected by Insurance Company
          mandates for white bagging to send the attached template letter, track
          and keep a copy of your submission, and follow up to discuss next
          steps.
        </p>
        <p>
          <em>
            *Please note that in making the decision on whether to accept
            white-bagged prescriptions under any circumstances from the
            Insurance Company, the practice should consider whether it currently
            accepts white-bagged prescriptions from other sources, such as
            manufacturer free-drug programs for financially-needy patients.
          </em>
        </p>

        <hr />
        <p
          className="text-center pb-1"
          style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
        >
          A template letter your practice can use to respond to these instances
          where insurance companies and/or PBMs attempt to requiring your
          practice to accept white or brown bagged medications.
        </p>
        <div className="text-center">
          <a
            href="https://coapharmacy2.com/wp-content/uploads/2022/05/COPA-COA_White-bagging-legal-letter.docx"
            className="btn btn-secondary"
            target={"_blank"}
            download="download"
          >
            DOWNLOAD
          </a>
        </div>
      </Container>
      </div>
        </div>
     
      ):(
        <div id="noAuthCan">
        <Container>
        <p>
        When our members take an active role as an advocate for patient rights
          and freedom of choice, you are doing your part to make sure that
          issues facing your profession and your economic future are addressed.
          COPA’s goal is to build an effective and dynamic advocacy network that
          will translate into positive results for our members and their
          patients. COPA remains committed to grassroot advocacy to influence
          the public policy debate and enactment of legislation to prohibit
          practices that are harmful to patients.</p>
          <p>
          A growing – and extremely concerning – trend that has emerged is the
          concept of mandatory “white bagging” of oncology medications that are
          administered in-office by community oncology practices. In seeming
          unison, several health insurance companies (who have integrated PBMs
          and specialty pharmacies) have begun to mandate that certain IV drugs
          that were previously purchased by community oncology practices and
          administered in-office to patients, and now requiring that they be
          filled by the PBM-owned specialty pharmacy. These are drugs that
          historically have been administered in-office by community oncology
          practices and billed to patients’ medical benefit (as opposed to their
          pharmacy benefit). In essence, these payers (which include Anthem Blue
          Cross of California, Blue Cross Blue Shield of Tennessee and Cigna)
          have mandated that cancer patients receive their chemotherapy through
          white or brown bagging, to be supplied by the affiliated specialty
          pharmacy.
        </p>
          </Container>
        </div>
      )}
      <PostTeaser />
    </div>
  );
};

export default BrownWhite;
