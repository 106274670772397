import React, { useEffect, useState }  from "react";
import { Container } from "reactstrap";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Copay = () => {
const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
const [items, setItems] = useState(null);
const domain = "login.mycoa.io";
const { slug } = useParams();
const baseURL = "https://mycoa.io/api";

useEffect(() => {
  const getUserMetadata = async () => {
    try {
      const accessToken1 = await getAccessTokenSilently({
        audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();
      return user_metadata;
    } catch (e) {
      console.log(e.message);
    }
  };
  getUserMetadata().then(function (result) {
    console.info("result", result);
     document.querySelector("#authCan").classList.add("hide");
     document.querySelector("#noAuthCan").classList.add("hide");
    //if (isAuthenticated && result && result.COPA === "COPA") {
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1) {
      document.querySelector("#authCan").classList.remove("hide");
      document.querySelector("#noAuthCan").classList.add("hide");
    } else {
      document.querySelector("#authCan").classList.add("hide");
      document.querySelector("#noAuthCan").classList.remove("hide");
    }
  });
}, [getAccessTokenSilently, user?.sub]);

  return (
    <div className="page-wrapper my-6">
        {isAuthenticated ? (
        <div>
      <div id="noAuthCan">  
      <Container>
        <p>
          For those encountering these types of audits where standard operating
          procedures (SOPs) need to exist, Frier Levitt is willing to do the
          following for you at a discounted rate of $4,000 ...
        </p>
        </Container>
      </div>
      <div id="authCan" className="hide">
      <Container>
        <p>
          For those encountering these types of audits where standard operating
          procedures (SOPs) need to exist, Frier Levitt is willing to do the
          following for you at a discounted rate of $4,000.
        </p>
        <p>
          Creation of Copayment Collection SOPs: Under this Flat Fee, Frier
          Levitt shall provide the following legal services regarding copay
          collection policies/procedures: (1) Prepare written Standard Operating
          Procedures (SOPs) governing the pharmacy’s copayment collection
          practices in compliance with State and Federal law, (2) prepare
          initial billing letters/statements to patients that comport with
          Federal and State laws regarding collection of copayments, (3) prepare
          follow-up letters to be sent to the patient that comport with Federal
          and State laws regarding collection of copayments, and (4) provide
          legal advice and counseling to the pharmacy in furtherance of
          assisting the pharmacy in implementing its new copayment collection
          SOPs.
        </p>
      </Container>
      </div>
      </div>
      ):(
        <div id="noAuthCan">  
      <Container>
        <p>
          For those encountering these types of audits where standard operating
          procedures (SOPs) need to exist, Frier Levitt is willing to do the
          following for you at a discounted rate of $4,000.
        </p>
        </Container>
      </div>
      )
      }
       <PostTeaser />
    </div>
  );
};

export default Copay;
