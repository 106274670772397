import React, { useState, useEffect } from "react";
// ** React Imports
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  //Pagination,
  //PaginationItem,
  //PaginationLink,
  Row,
} from "reactstrap";
import styled from "styled-components";
import Select from "react-select";
import { Input } from "reactstrap";
import { CheckCircle, Search, Star, XCircle } from "react-feather";
import {
  PageWrapper,
  TitleWrapper,
} from "../../assets/styled-components/components";
import { useDispatch, useSelector } from "react-redux";
import { listMyPartnerEvents } from "../../Store/Action/ProductAction.js";
import Pagination from "./components/Pagination";
import { PaginatedList } from "react-paginated-list";
import { forEach } from "lodash";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const PartnerEventsListPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  // const [rows, setRows] = useState([])
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const store = useSelector((state) => state.products.list);
  const history = useHistory();

  useEffect(() => {
    const userID = user.sub.replace("auth0|", "");
    // const userID = "63e54c0e893cae0011f95bdc";
    dispatch(listMyPartnerEvents(userID));
  }, [dispatch]);

  useEffect(() => {
    getProductList();
  }, [searchQuery, searchFilter]);

  const [productList, setProductList] = useState(store);

  useEffect(() => {
    setProductList(store);
    // setProductCount(store.length);
    if (store != null) {
      setProductCount(store.length);
    }
  }, [store]);

  const getProductList = () => {
    if (store != null) {
      let allProduct = store;
      if (searchFilter.length) {
        allProduct = allProduct.filter(function (obj) {
          if (
            obj["product_category"] != undefined &&
            obj["product_category"].length
          ) {
            let a = obj["product_category"].filter((value) =>
              searchFilter.includes(value)
            );
            return a.length > 0;
          } else {
            return false;
          }
        });
      }
      if (searchQuery) {
        allProduct = allProduct.filter(function (obj) {
          return (
            (obj["title"] ? obj["title"] : "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (obj["description"] ? obj["description"] : "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        });
      }
      setProductCount(allProduct.length);
      setProductList(allProduct);
    }
  };

  //Search list of objects
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const routeChange = (id) => {
    return history.push({
      pathname: "/partner-event-detail",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };
  const routeEditChange = (id) => {
    return history.push({
      pathname: "/edit-partner-event",
      search: "?id=" + id,
      state: { fromPopup: true },
    });
  };

  return (
    store != [] && (
      <PageWrapper id="page-wrapper" className="pb-6">
        <TitleWrapper className="pt-6 mb-6">
          <Container>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div>
              <h3 className="text-white">All Partner Events</h3>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="add-partner-event">All Partner Events</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            <Button
              tag={Link}
              to="/add-partner-event"
              color="light"
              size="sm"
              className="view-product"
            >
              Add Partner Event
            </Button>
          </div>
          </Container>
        </TitleWrapper>

        <ProductListWrapper>
          <Container>
            <Row>
              <Col md="12" lg="12" className="mt-5 mt-md-0">
                <div className="d-flex-column d-sm-flex justify-content-between align-items-end">
                  <h6>{productCount} results found</h6>
                </div>
                <SearchWrapper>
                  <Input
                    type="text"
                    className="search"
                    id="search"
                    placeholder="Search"
                    size={"lg"}
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <Search size={"18px"} />
                </SearchWrapper>
                {productList != null && (
                  <PaginatedList
                    list={productList}
                    nextText="Next"
                    prevText="Previous"
                    itemsPerPage={5}
                    renderList={(list) => (
                      <>
                        {list.map((row, id) => {
                          return (
                            <ProductListCard key={id}>
                              <Card>
                                <CardBody>
                                  <div className="product-image">
                                    <img
                                      src={row.event_image}
                                      alt=""
                                      width={"100%"}
                                    />
                                  </div>
                                  <div className="description">
                                    <h3>{row.title}</h3>
                                    <div className="verified-status p-0">
                                      {row.status === "Approved" ? (
                                        <Alert
                                          color="info"
                                          className="p-1 px-2 d-flex align-items-center"
                                        >
                                          <CheckCircle
                                            size="14"
                                            className="mr-1"
                                          />
                                          {row.status}
                                        </Alert>
                                      ) : (
                                        <Alert
                                          color="danger"
                                          className="p-1 px-2 d-flex align-items-center"
                                        >
                                          <XCircle size="14" className="mr-1" />
                                          {row.status}
                                        </Alert>
                                      )}
                                    </div>

                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: row.description,
                                      }}
                                    ></p>
                                  </div>
                                </CardBody>
                                <CardFooter className="bg-white">
                                  <ProductMeta className="unstyled">
                                    {/* <li className="manufacturer">
                                      <span className="title">
                                        Manufacturer:{" "}
                                      </span>
                                      {row.manufacturer}
                                    </li> */}
                                    <li className="ml-auto ">
                                      <Button
                                        tag={Link}
                                        onClick={() => routeEditChange(row._id)}
                                        color="success"
                                        size="sm"
                                        className="view-product mr-2"
                                      >
                                        Edit Partner Event
                                      </Button>

                                      <Button
                                        tag={Link}
                                        onClick={() => routeChange(row._id)}
                                        color="primary"
                                        size="sm"
                                        className="view-product"
                                      >
                                        View Partner Event
                                      </Button>
                                    </li>
                                  </ProductMeta>
                                </CardFooter>
                              </Card>
                            </ProductListCard>
                          );
                        })}
                      </>
                    )}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </ProductListWrapper>
      </PageWrapper>
    )
  );
};

export default PartnerEventsListPage;

export const ProductListWrapper = styled.main`
  .pagination {
    li {
      margin-bottom: 0;
      line-height: 1.2;

      transition: background 300ms ease;

      &:not(.active):hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;

  input {
    padding-right: 40px;
  }

  svg {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ProductListCard = styled.section`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  .card-body {
    display: grid;
    grid-template-columns: 200px 2fr;
    gap: 20px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;

      .product-image {
        img {
          height: unset;
        }
      }
    }
  }

  .product-image {
    position: relative;

    img {
      max-height: 150px;
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
  }

  .description {
    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }

    .verified-status {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;

      .alert {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

export const ProductMeta = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;

  li {
    margin-bottom: 0 !important;

    &:not(:last-child) {
      background-color: #f1f1f1;
      padding: 5px 13px;
      border-radius: 100px;
      font-size: 14px;
    }

    &.rating {
      span {
        font-size: 14px;
      }

      .alert {
        padding: 7px 13px;
        border-radius: 100px;
        line-height: 1.2;
        color: #ae840c;
        font-weight: 600;

        svg {
          stroke: var(--warning);
          fill: var(--warning);
        }
      }
    }
  }

  .view-product {
    padding: 7px 20px;
  }

  .title {
    font-weight: 600;
  }
`;
