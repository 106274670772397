import React, { useEffect, useState }  from "react";
import { Container } from "reactstrap";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Denial = () => {
const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
const [items, setItems] = useState(null);
const domain = "login.mycoa.io";
const { slug } = useParams();
const baseURL = "https://mycoa.io/api";

useEffect(() => {
  const getUserMetadata = async () => {
    try {
      const accessToken1 = await getAccessTokenSilently({
        audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();
      return user_metadata;
    } catch (e) {
      console.log(e.message);
    }
  };
  getUserMetadata().then(function (result) {
    console.info("result", result);
     document.querySelector("#authCan").classList.add("hide");
     document.querySelector("#noAuthCan").classList.add("hide");
    //if (isAuthenticated && result && result.COPA === "COPA") {
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1) {
      document.querySelector("#authCan").classList.remove("hide");
      document.querySelector("#noAuthCan").classList.add("hide");
    } else {
      document.querySelector("#authCan").classList.add("hide");
      document.querySelector("#noAuthCan").classList.remove("hide");
    }
  });
}, [getAccessTokenSilently, user?.sub]);
  return (
    <div className="page-wrapper my-6">
      {isAuthenticated ? (
      <div>
      <div id="noAuthCan">  
      <Container>
        <p>
          COPA has negotiated a flat fee with the legal experts at Frier Levitt
          to represent pharmacies excluded from dispensing subsequent
          prescriptions after the initial prescription is filled.
        </p>
        <p>
          Frier letter would provide the following for practice pharmacies not
          being able to fill subsequent fills after the initial:
        </p>
        <ul>
          <li>
            For the flat fee of $500, Frier Levitt will prepare and submit a
            Demand Letter on Your behalf, challenging a PBM’s denial of you
            filling more than the initial prescription.
          </li>
          <li>
            The Demand Letter will include reference to State and Federal “any
            willing provider” laws, contract and manual provisions, and other
            applicable laws. The associated research alone to draft this letter
            would likely be over $500 based on our normal hourly rates.
          </li>
          </ul>
      </Container>
      </div>
      <div id="authCan" className="hide">
      <Container>
        <p>
          COPA has negotiated a flat fee with the legal experts at Frier Levitt
          to represent pharmacies excluded from dispensing subsequent
          prescriptions after the initial prescription is filled.
        </p>
        <p>
          Frier letter would provide the following for practice pharmacies not
          being able to fill subsequent fills after the initial:
        </p>
        <ul>
          <li>
            For the flat fee of $500, Frier Levitt will prepare and submit a
            Demand Letter on Your behalf, challenging a PBM’s denial of you
            filling more than the initial prescription.
          </li>
          <li>
            The Demand Letter will include reference to State and Federal “any
            willing provider” laws, contract and manual provisions, and other
            applicable laws. The associated research alone to draft this letter
            would likely be over $500 based on our normal hourly rates.
          </li>
          <li>
            Frier Levitt will not only draft the Demand Letter on your behalf,
            but will participate in a telephone conference call with the PBM’s
            representatives, aimed at resolving the denial.
          </li>
          <li>
            This Flat Fee is intended to not only provide an avenue to challenge
            PBMs’ exclusionary conduct in numbers in an efficient manner, but
            allow any one practice to begin the process of challenging PBM’s
            from only allowing initial prescriptions from being filled within
            physician dispensing/retail pharmacies.
          </li>
        </ul>
        <div className="text-center">
          <a
            href="https://coapharmacy2.com/wp-content/uploads/2022/05/COPA-COA_Flat-Fee-Proposal-Re-Exclusion-From-PBM-Network-1.pdf"
            target={"_blank"}
            className="btn btn-secondary text-uppercase"
            download="download"
          >
            Download
          </a>
        </div>
      </Container>
      </div>
      </div>):(
            <div id="noAuthCan">  
            <Container>
            <p>
          COPA has negotiated a flat fee with the legal experts at Frier Levitt
          to represent pharmacies excluded from dispensing subsequent
          prescriptions after the initial prescription is filled.
        </p>
        <p>
          Frier letter would provide the following for practice pharmacies not
          being able to fill subsequent fills after the initial:
        </p>
        <ul>
          <li>
            For the flat fee of $500, Frier Levitt will prepare and submit a
            Demand Letter on Your behalf, challenging a PBM’s denial of you
            filling more than the initial prescription.
          </li>
          <li>
            The Demand Letter will include reference to State and Federal “any
            willing provider” laws, contract and manual provisions, and other
            applicable laws. The associated research alone to draft this letter
            would likely be over $500 based on our normal hourly rates.
          </li>
          </ul>
            </Container>
            </div>

      )
}
      <PostTeaser />
    </div>
  );
};

export default Denial;
