import React,  { useEffect }  from "react";

import featuredImage from "../../assets/images/comment-letters-featured.jpg";
import { SubHeaderWrapper } from "../../components/GlobalStyles";
import { Col, Container, Row } from "reactstrap";
import HorizontalArticle from "../../components/horizontal-article/HorizontalArticle";
import articlePlaceholder from "../../assets/images/comment-letters-placeholder.jpg";
import { Download, ExternalLink, FileText } from "react-feather";
import styled from "styled-components";
import PostTeaser from "../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PbmInsurer = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const domain = "login.mycoa.io";
  const { slug } = useParams();
  const baseURL = "https://mycoa.io/api";

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then(function (result) {
      console.info("result", result);
       document.querySelector("#authCanPbm").classList.add("hide");
       document.querySelector("#noAuthCanPbm").classList.remove("hide");
      //if (isAuthenticated && result && result.COPA === "COPA" && result!=undefined) {
        if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1 && result!=undefined) {
        document.querySelector("#authCanPbm").classList.remove("hide");
        document.querySelector("#noAuthCanPbm").classList.add("hide");
      } else {
        document.querySelector("#authCanPbm").classList.add("hide");
        document.querySelector("#noAuthCanPbm").classList.remove("hide");
      }
    });
  }, [getAccessTokenSilently, user?.sub]);
  return (
    <div className="page-wrapper">
       {isAuthenticated ? (
     <div>
       <SubHeaderWrapper className="my-6">
        <Container>
          <Row className="mb-6 align-items-center">
            <Col lg="6">
              <img src={featuredImage} alt="featured image" width="100%" />
            </Col>
            <Col lg="6" className="mt-4 mt-lg-0">
              <h2>Resources to Address PBM & Insurer Abuses</h2>
              <p>
                COPA has assembled a library of resources, templates, and
                knowledge to help you overcome PBM and insurer abuses that hurt
                your patients and practices. <em>Take Action Today!</em>
              </p>
            </Col>
          </Row>
        </Container>
      </SubHeaderWrapper>
     <div id="noAuthCanPbm">
      <section className="mb-6">
        <Container>
          <AbuseList className="absuses-list">
            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Board of Pharmacy Complaint Letter for Egregious PBM Conduct
                </h4>
                <p>
                  Use this template to contact your local board of pharmacy and
                  submit a complaint about egregious PBM conduct that impacted
                  your patient.
                </p>
              </div>
            </div>
          </AbuseList>
        </Container>
      </section>
     </div>
     <div id="authCanPbm" className="hide">
      <section className="mb-6">
        <Container>
          <AbuseList className="absuses-list">
            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Board of Pharmacy Complaint Letter for Egregious PBM Conduct
                </h4>
                <p>
                  Use this template to contact your local board of pharmacy and
                  submit a complaint about egregious PBM conduct that impacted
                  your patient.
                </p>
                <a
                  href="https://coapharmacy2.com/wp-content/uploads/2022/05/COPA-COA_Template-Letter-Regarding-Treatment-Practices.docx
"
                  target={"_blank"}
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1"> Download</span>{" "}
                  <Download size="16px" />
                </a>
              </div>
            </div>
            {/* item end */}

            <div className="item">
              <div className="icon">
                <FileText />
              </div>

              <div className="content">
                <h4 className="weight-700">
                  Brown/White Bagging Response Template Letter
                </h4>
                <p>
                  A template letter your practice can use to respond to these
                  instances where insurance companies and/or PBMs attempt to
                  requiring your practice to accept white or brown bagged
                  medications.
                </p>
                <Link
                  to="/brown-white-bagging-response-template-letter/"
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1">Read More</span>{" "}
                  <ExternalLink size={"16px"} />
                </Link>
              </div>
            </div>
            {/* item end */}

            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Copay Collection Audit Legal Support Engagement
                </h4>
                <p>
                  COPA has negotiated a discounted rate with the legal experts
                  at Frier Levitt to support pharmacies in managing copay
                  collection audits.
                </p>
                <Link
                  to="/copay-collection-audit-legal-support-engagement/"
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1">Read More</span>{" "}
                  <ExternalLink size={"16px"} />
                </Link>
              </div>
            </div>
            {/* item end */}

            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Denial After First Fill Legal Engagement Letter
                </h4>
                <p>
                  COPA has negotiated a flat fee with the legal experts at Frier
                  Levitt to represent pharmacies excluded from dispensing
                  subsequent prescriptions after the initial prescription is
                  filled.
                </p>
                <Link
                  to="/denial-after-first-fill-legal-engagement-letter/"
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1">Read More</span>{" "}
                  <ExternalLink size={"16px"} />
                </Link>
              </div>
            </div>
            {/* item end */}

            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Part D Plan or PBM Patient Complaint Form and Guide
                </h4>
                <p>
                  These resources include a sample complaint form and guide to
                  help educate patients on their rights, and provide guidance on
                  how to submit complaints about their Medicare Part D plan or
                  PBM.
                </p>
                <Link
                  to="/part-d-plan-or-pbm-patient-complaint-form-and-guide/"
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1">Read More</span>{" "}
                  <ExternalLink size={"16px"} />
                </Link>
              </div>
            </div>
            {/* item end */}

            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Patient Steerage Complaint Collection Form
                </h4>
                <p>
                  Use this form to ensure you collect all the needed details for
                  complaints about PBM patient steerage.
                </p>
                <a
                  href="https://coapharmacy2.com/wp-content/uploads/2022/05/COPA-COA_Claim-Incident-Questionnaire.docx"
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1"> Download</span>{" "}
                  <Download size="16px" />
                </a>
              </div>
            </div>
            {/* item end */}

            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Patient Trolling/Patient Steerage Template Letter & HIPAA
                  Complaint Form
                </h4>
                <p>
                  A simple template letter you can use to notify PBMs that are
                  “trolling” or steering your patients illegally. Use the
                  template in conjunction with the official HHS Office of Civil
                  Right complaint form.
                </p>
                <a
                  href="#"
                  className="text-secondary d-flex align-items-center"
                >
                  <span className="mr-1"> Download</span>{" "}
                  <Download size="16px" />
                </a>
              </div>
            </div>
            {/* item end */}
          </AbuseList>
        </Container>
      </section>
      </div>
      </div>
       ) : (
        //Non COPA User
        <div id="noAuthCanPbm">
          <SubHeaderWrapper className="my-6">
        <Container>
          <Row className="mb-6 align-items-center">
            <Col lg="6">
              <img src={featuredImage} alt="featured image" width="100%" />
            </Col>
            <Col lg="6" className="mt-4 mt-lg-0">
              <h2>Resources to Address PBM & Insurer Abuses</h2>
              <p>
                COPA has assembled a library of resources, templates, and
                knowledge to help you overcome PBM and insurer abuses that hurt
                your patients and practices. <em>Take Action Today!</em>
              </p>
            </Col>
          </Row>
        </Container>
      </SubHeaderWrapper>
      <section className="mb-6">
        <Container>
          <AbuseList className="absuses-list">
            <div className="item">
              <div className="icon">
                <FileText />
              </div>
              <div className="content">
                <h4 className="weight-700">
                  Board of Pharmacy Complaint Letter for Egregious PBM Conduct
                </h4>
                <p>
                  Use this template to contact your local board of pharmacy and
                  submit a complaint about egregious PBM conduct that impacted
                  your patient.
                </p>
              </div>
            </div>
            {/* item end */}
          </AbuseList>
        </Container>
      </section>
        </div>
        //Non COPA User End
      )
    }
  <PostTeaser />
    </div>
  );
};

export default PbmInsurer;

export const AbuseList = styled.div`
  h4 {
    font-size: 28px;
    color: var(--primary);
  }
  a {
    font-size: 16px;
    font-weight: 600;
  }

  .item {
    &:not(:last-child) {
      margin-bottom: 20px;
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 20px;
    }

    display: flex;

    gap: 15px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary);
      flex-basis: 100px;
      min-width: 100px;
      border-radius: 0.428rem;
      padding: 15px;

      svg {
        color: #fff;
        width: 50px;
        height: 50px;
        stroke-width: 1.2px;
      }
    }

    @media (max-width: 576px) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      a {
        justify-content: center;
      }

      h4 {
        font-size: 22px;
      }
    }
  }
`;
