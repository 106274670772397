import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Tooltip,
} from "reactstrap";
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation-safe";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Trash2, Plus } from "react-feather";
import {
  showPartnerEventDetails,
  updatePartnerEvent,
} from "../../Store/Action/ProductAction.js";
import {
  PageWrapper,
  TitleWrapper,
} from "../../assets/styled-components/components";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  valSwalSuccess,
  valSwalConfirm,
  valSwalFailure,
  swalalertWithMsg,
} from "../../dev-config/global.js";
import {DateConfig, TimeConfig} from "../../dev-config/global"
import { Editor } from "@tinymce/tinymce-react";
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

const EditProduct = () => {
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [_id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [body, SetBody] = useState(null);
  const [registrationUrl, setRegistrationUrl] = useState(null);
  const [registrationCode, setRegistrationCode] = useState(null);

  /* *** File upload function start *** */
  /* Feature image */
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);

  const dropImageRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(showPartnerEventDetails(id));
    }
  }, [dispatch]);

  const store = useSelector((state) => state.products);

  useEffect(() => {
    let data = store.details ? store.details : [];

    setId(data?._id);
    setTitle(data?.title);
    if (data.description != null) {
      const contentDataState = ContentState.createFromBlockArray(convertFromHTML(data.description));
      SetBody(draftToHtml(convertToRaw(contentDataState)));
    }
    setRegistrationUrl(data?.registration_url);
    setRegistrationCode(data?.registration_code);
    setStartDate(data?.start_date_time);
    setEndDate(data?.end_date_time);

    setFeaturedImage(data?.event_image);
    setIsPreviewAvailable(data?.event_image);

  }, [store]);

  //Handling image
  const onDropImage = (files) => {
    const [uploadedFile] = files;
    setFeaturedImage(uploadedFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setIsPreviewAvailable(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
  };

  const updateBorder = (dragState, reference) => {
    if (dragState === "over") {
      reference.current.style.border = "1px solid #000";
    } else if (dragState === "leave") {
      reference.current.style.border = "1px dashed #046f9c";
    }
  };
  const removeImage = (e) => {
    setFeaturedImage(null);
  };

  const routeListChange = () => {
    history.push("my-partner-events-list");
  };

  // ** Function to handle form submit
  const onSubmit = async (e, errors, values) => {
      console.log(errors,'errors')
    var validation = {
      title: "Product Title",
      description: "Description",
      // registration_url: "Registration URL",
      registration_code: "Registration Code",
      start_date: "Start Date",
      start_time: "Start Time",
      end_date: "End Date",
      end_time: "End Time",
    };
    if (errors.length > 0) {
      valSwalFailure("Error!", "Please enter " + validation[errors[0]]);
      return false;
    } else if (featuredImage == null) {
      valSwalFailure("Error!", "Please Choose the Featured Image");
      return false;
    } else {
      let payload = values;
      payload._id = values._id;
      payload.description = body;
      payload.user_id = user.sub.replace("auth0|", "");
      // payload.user_id = "63e54c0e893cae0011f95bdc";

      var formDataValue = new FormData();
      for (var key in payload) {
        formDataValue.append(key, payload[key] ? payload[key] : "");
      }
      formDataValue.append("event_image", featuredImage);

      swalalertWithMsg(true, "Please Wait!");
      dispatch(updatePartnerEvent(formDataValue))
        .then((res) => {
          history.push("my-partner-events-list");
          valSwalSuccess("Success", "Partner Event Updated Successfully!");
        })
        .catch((err) => {
          valSwalFailure("Error", "Something went wrong please try again");
        });
    }
  };

  return (
    <PageWrapper id="page-wrapper" className="pb-6">
      <TitleWrapper className="pt-6 mb-6">
        <Container>
          <h3 className="text-white">Edit Partner Event</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="my-product-list">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Edit Partner Event</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </TitleWrapper>

      <Container>
        <FormWrapper>
          <AvForm onSubmit={onSubmit}>
            <Row>
              <Col lg="12" xl="12">
                <Card style={{ position: "sticky", top: "100px" }}>
                  <CardBody className="pb-1">
                    <Row>
                      <AvInput
                        type="hidden"
                        id="_id"
                        name="_id"
                        placeholder="Name"
                        value={_id}
                      />
                      <Col lg="12">
                        <FormGroup>
                          <Label
                            for="title"
                            className="d-flex align-items-center"
                          >
                            Partner Event Title{" "}
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <AvInput
                            name="title"
                            id="title"
                            value={title}
                            placeholder="Partner Event Title"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label
                            for="description"
                            className="d-flex align-items-center"
                          >
                            Description
                          </Label>
                          <EditorWrapper>
                            <Editor
                              apiKey="fyfo3vbgn64ewzoo93vi77ca6hzt3wojx5omqrslccdcqjt3"
                              theme="modern"
                              readOnly={origin == "declined"}
                              init={{
                                height: 250,
                                // width: 1100,
                                branding: false,
                                menubar: false,
                                // statusbar: false,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                ],
                                // toolbar:
                                //   "image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code preview",
                                toolbar:
                                  "bold italic | alignleft aligncenter alignright | bullist numlist",
                                images_upload_url: "/api/page-template/aws",
                                images_file_types: "jpg,jpeg,png,svg,webp",
                                paste_data_images: true,

                                setup: (editor) => {
                                  editor.on("init", () => {
                                    const maxWordCount = 300;
                                    const wordCountWarningThreshold =
                                      maxWordCount - 20;

                                    const checkWordCount = () => {
                                      const content = editor.getContent({
                                        format: "text",
                                      });
                                      const words = content
                                        .split(/\s+/)
                                        .filter(Boolean);
                                      if (words.length > maxWordCount) {
                                        // editor.setMode('readonly');
                                        const truncatedContent = words
                                          .slice(0, maxWordCount)
                                          .join(" ");
                                        editor.setContent(truncatedContent);
                                        alert(
                                          "You have reached the maximum word count limit (300 words)."
                                        );
                                      }
                                      // else if (words.length > wordCountWarningThreshold) {
                                      //   alert('You are approaching the maximum word count limit (300 words).');
                                      // }
                                      else {
                                        editor.setMode("design");
                                      }
                                    };
                                    editor.on("input", checkWordCount);
                                    editor.on("paste", () => {
                                      setTimeout(checkWordCount, 0);
                                    });
                                  });
                                },
                              }}
                              value={body}
                              onEditorChange={(content) => {
                                SetBody(content);
                              }}
                            />
                          </EditorWrapper>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label
                            for="start_date"
                            className="d-flex align-items-center"
                          >
                            Start Date
                          </Label>
                          <AvInput
                            type="date"
                            id="start_date"
                            name="start_date"
                            placeholder="Start Date"
                            min={new Date()}
                            required
                            value={startDate ? startDate.split("T")[0] : new Date()}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label
                            for="start_time"
                            className="d-flex align-items-center"
                          >
                            Start Time
                          </Label>
                          <AvInput
                            type="time"
                            id="start_time"
                            name="start_time"
                            placeholder="Start Time"
                            value={(new Date(startDate).toTimeString().slice(0,5))}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label
                            for="end_date"
                            className="d-flex align-items-center"
                          >
                            End Date
                          </Label>
                          <AvInput
                            type="date"
                            id="end_date"
                            name="end_date"
                            placeholder="End Date"
                            required
                            min={startDate}
                            value={endDate ? endDate.split("T")[0] : new Date()}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label
                            for="end_time"
                            className="d-flex align-items-center"
                          >
                            End Time
                          </Label>
                          <AvInput
                            type="time"
                            id="end_time"
                            name="end_time"
                            placeholder="End Time"
                            required
                            value={(new Date(endDate).toTimeString().slice(0,5))}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label
                            for="registration_url"
                            className="d-flex align-items-center"
                          >
                            Registration URL
                          </Label>
                          <AvInput
                            name="registration_url"
                            id="registration_url"
                            value={registrationUrl}
                            placeholder="Registration URL"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <Label
                            for="registration_code"
                            className="d-flex align-items-center"
                          >
                            Registration Code
                          </Label>
                          <AvInput
                            name="registration_code"
                            id="registration_code"
                            placeholder="Registration Code"
                            value={registrationCode}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <Card className="mb-3">
                          <CardHeader className="bg-white">
                            <AvInput
                              type="hidden"
                              id="old_event_image"
                              name="old_event_image"
                              value={featuredImage}
                            />
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Featured Image
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <DropzoneWrapper className="featured-image">
                              <Dropzone
                                onDrop={onDropImage}
                                onDragEnter={() =>
                                  updateBorder("over", dropImageRef)
                                }
                                onDragLeave={() =>
                                  updateBorder("leave", dropImageRef)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps({
                                      className: "drop-zone ",
                                    })}
                                    ref={dropImageRef}
                                    style={{ height: "unset" }}
                                  >
                                    <input
                                      {...getInputProps()}
                                      accept=".jpg,.jpeg,.png,.svg"
                                    />
                                    {!featuredImage ? (
                                      <div>Drag or Click to Upload</div>
                                    ) : (
                                      ""
                                    )}
                                    {featuredImage ? (
                                      <div className="selected-item-wrapper w-100">
                                        <div className="selected-item">
                                          <Button className="remove-selected">
                                            <Trash2
                                              size="18"
                                              onClick={removeImage}
                                            />
                                          </Button>
                                          <img
                                            src={isPreviewAvailable}
                                            width="100%"
                                            alt=""
                                          />{" "}
                                          {/* {file && (
                                      <div className="file-name mt-1">
                                        <strong>Selected: </strong> {featuredImage.name}
                                      </div>
                                    )} */}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </DropzoneWrapper>
                            {/*<StyledDropzone />*/}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mt-3 align-items-center">
                      <Col md="12">
                        <div className="text-right">
                          <PublishButton
                            color="success"
                            size="sm"
                            className="mb-3"
                          >
                            Update
                          </PublishButton>
                          <CancelButton
                            color="danger"
                            size="sm"
                            className="mb-3 ml-2"
                            onClick={() => routeListChange()}
                          >
                            Discard
                          </CancelButton>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </AvForm>
        </FormWrapper>
      </Container>
    </PageWrapper>
  );
};
export default EditProduct;

export const FormWrapper = styled.section`
  .react-select {
    .select__multi-value__label {
      color: #fff;
    }
  }
`;

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#ced4da";
};

export const DropzoneWrapper = styled.div`
  display: block;

  /* only for featured image  */
  &.featured-image {
    img {
      max-height: 325px;
      object-fit: contain;
    }
  }
  /* /featured image */

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .remove-selected {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  .drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-width: 1px;
    border-radius: 2px;

    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:not(:hover) {
      border-color: #ced4da !important;
    }

    &:hover {
      border: 1px dashed var(--primary) !important;
    }

    & > div:not(.selected-item-wrapper) {
      padding: 20px;
      width: 100%;
      text-align: center;
    }
  }

  div {
    margin-bottom: 0;
    font-size: 14px;
  }

  .selected-item {
    position: relative;

    .remove-selected {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      padding: 2px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 calc(50% - 8px);
  }

  div:not(.image-selected) {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .selected-item {
    position: static;

    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
`;

export const CategoryTree = styled.ul`
  list-style: none;
  li {
    text-transform: capitalize;
  }

  li ul {
    margin-top: 0.5rem;
    list-style: none;
  }
`;

export const PublishButton = styled(Button)`
  font-size: 1rem;
`;

export const CancelButton = styled(Button)`
  font-size: 1rem;
`;

export const AddNewCategory = styled.div``;

export const AddCategoryBtn = styled(Button)`
  border-width: 1px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f3f6ff;
  font-size: 14px;
`;

export const AddNewCategoryWrapper = styled.div`
  font-size: 0.857rem;

  input {
    font-size: 0.857rem;
  }
`;

export const WysiwygEditor = styled.div`
  .demo-editor.rdw-editor-main,
  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .demo-editor.rdw-editor-main {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0px 1rem;
    min-height: 300px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5rem 0px;
  }

  .rdw-editor-wrapper:focus-within {
    border-color: #3365d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 51, 114, 0.25);
    border-radius: 0.25rem;

    .demo-editor.rdw-editor-main,
    .rdw-editor-toolbar {
      border-color: #3365d6;
    }

    .demo-editor.rdw-editor-main {
      border-top-color: #ced4da;
    }
  }
`;

export const AddGalleryButton = styled(Button)`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  border-width: 1.5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditorWrapper = styled.div`
  .tox-tinymce {
    border-radius: 0.25rem;
    border-color: #d8d6de;

    * {
      border-color: #d8d6de !important;
    }
  }

  .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 0 !important;
  }
`;
