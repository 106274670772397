import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./assets/scss/styles.scss";
import Loading from "./components/Loading";
import Home from "./views/home/Home";
// import Profile from "./views/Profile";
import Profile from "./views/user/edit/index.js";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import CommentLetters from "./views/article/category/CommentLetters";
import Post1 from "./views/article/single/post1";
import PostTemplate from "./views/article/single/PostTemplate";
import Post3 from "./views/article/single/post3";
import Post4 from "./views/article/single/post4";
import ACHCSpecialtyPharmacyAccreditation from "./views/resources/achc-specialty-accreditation";
import OncologyDistinction from "./views/resources/oncology-distinction";
import CallsAndRecordings from "./views/membership/CallsAndRecordings";
import PageView from "./views/article/page-view/index";
import NotFoundPage from "./views/not-found/NotFoundPage";

// Abusues
import PbmAndInsurerAbuses from "./views/resources/pbm-and-insurer-abuses";

import BrownWhiteBagging from "./views/resources/pbm-and-insurer-abuses/brown-white-bagging-response-template-letter";
import CopayCollection from "./views/resources/pbm-and-insurer-abuses/copay-collection-audit-legal-support-engagement";
import DenialAfter from "./views/resources/pbm-and-insurer-abuses/denial-after-first-fill-legal-engagement-letter";
import PartDPlan from "./views/resources/pbm-and-insurer-abuses/part-d-plan-or-pbm-patient-complaint-form-and-guide";
import ACHCPharmacyAcc from "./views/resources/achc-pharmacy-accreditation";

// Rating
import Rating from "./views/Rating";
import RatingSingle from "./views/RatingSingle";

import PrimaryHeader from "./components/header/Header";
import PrimaryFooter from "./components/footer/Footer";
import Register from "./views/auth/Register";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Login from "./views/auth/Login";
import Post from "./views/post/post.js";
import FilteredPost from "./views/post/filteredPost.js";

// Page templates
import RightSidebar2 from "./views/article/page-templates/RightSidebar2";
import ResourceAtBottom from "./views/article/page-templates/ResourcesAtBottom";
import VideoWithContent from "./views/article/page-templates/VideoWithContent";
import VideoWithMinimalContent from "./views/article/page-templates/VideoWithMinimalContent";

// Pages

import WhatisCoa from "./views/what-is-coa/What-is-community-oncology";

//partner events
import AllPartnerEvents from "./views/partner-events/PartnerEventList";
import PartnerEventDetail from "./views/partner-events/PartnerEventDetail";
import MyPartnerEventList from "./views/partner-events/MyPartnerEventList";
import AddPartnerEvent from "./views/partner-events/AddPartnerEvent";
import EditPartnerEvent from "./views/partner-events/EditPartnerEvent";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app">
        <PrimaryHeader />
        <>
          <Switch>
            {/* Page templates */}
            <Route path="/right-sidebar-2" exact component={RightSidebar2} />
            <Route path="/resource-at-bottom" exact component={ResourceAtBottom} />
            <Route path="/video-with-content" exact component={VideoWithContent} />
            <Route path="/video-with-minimal-content" exact component={VideoWithMinimalContent} />

            <Route path="/" exact component={Home} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/profile" component={Profile} />
            {/* <Route path="/resources" component={ExternalApi} /> */}
            <Route path="/pbm-resources" component={CommentLetters} />
            <Route path="/post-1" exact component={Post1} />
            {/* <Route path="/post/:slug" exact component={PostTemplate} /> */}
            {/* <Route path="/post-2" exact component={Post2} /> */}
            <Route path="/post-3" exact component={Post3} />
            <Route path="/post-4" exact component={Post4} />

            <Route
              path="/achc-specialty-accreditation"
              component={ACHCSpecialtyPharmacyAccreditation}
            />
            <Route path="/achc-oncology-distinction" component={OncologyDistinction} />

            <Route path="/membership-calls-and-recordings" component={CallsAndRecordings} />

            {/* <Route
              path="/resources-for-pbm-and-insurer-abuses"
              component={PbmAndInsurerAbuses}
            /> */}

            <Route
              path="/brown-white-bagging-response-template-letter/"
              component={BrownWhiteBagging}
            />
            <Route
              path="/copay-collection-audit-legal-support-engagement/"
              component={CopayCollection}
            />
            <Route
              path="/denial-after-first-fill-legal-engagement-letter/"
              component={DenialAfter}
            />
            <Route
              path="/part-d-plan-or-pbm-patient-complaint-form-and-guide/"
              component={PartDPlan}
            />
            <Route path="/resources/achc-pharmacy-accreditation/" component={ACHCPharmacyAcc} />
            <Route path="/rating/" component={Rating} />
            <Route path="/rating-single/" component={RatingSingle} />
            <Route path="/search/:search" component={FilteredPost} />

            <Route path="/about/what-is-community-oncology" component={WhatisCoa} />
            <Route path="/page-not-found" exact component={NotFoundPage} />

            {/* partner-events routes*/}

            <Route path="/partner-events-list" exact component={AllPartnerEvents} />
            <Route path="/partner-event-detail" exact component={PartnerEventDetail} />
            <Route path="/my-partner-events-list" exact component={MyPartnerEventList} />
            <Route path="/add-partner-event" exact component={AddPartnerEvent} />
            <Route path="/edit-partner-event" exact component={EditPartnerEvent} />

            {/* Changed Routes */}
            <Route path="/:category/:subcategory/:slug" exact component={PageView} />
            <Route path="/:category/:slug" component={Post} />
            <Route path="/:slug" component={Post} />
          </Switch>
        </>
        <PrimaryFooter />
      </div>
    </Router>
  );
};

export default App;
