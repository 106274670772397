import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import featuredImage from "../../assets/images/comment-letters-featured.jpg";
import { SubHeaderWrapper } from "../../components/GlobalStyles";
import PostTeaser from "../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

// export const productsGenerator = quantity => {
//   const items = [];
//   for (let i = 0; i < quantity; i++) {
//     items.push({ standards: i, requirement: `Item name ${i}`, download: 2100 + i, dept: 123444 + i, explanation: `Explanation ${i}` });
//   }
//   console.log("items", items)
//   return items;
// };

// const products = productsGenerator(100);

const Achc = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [items, setItems] = useState(null);
  const domain = "login.mycoa.io";
  const { slug } = useParams();
  const baseURL = "https://mycoa.io/api";

  const htmlFormatter = (cell) => {
    return (
      <div
        dangerouslySetInnerHTML={
          cell != null ? { __html: `<p>${cell}</p>` } : { __html: `<p></p>` }
        }
      />
    );
  };

  useEffect(() => {
    axios.get(`${baseURL}/page-template/achc-accreditation`).then((response) => {
      setItems(response.data.data);
      console.log("items", items);
    });
  }, []);

  const columns = [
    {
      dataField: "standards",
      text: "ACHC Standards",
      sort: true,
    },
    {
      dataField: "requirement",
      text: "Requirement",
      formatter: htmlFormatter,
    },
    {
      dataField: "download",
      text: "Download",
      formatter: htmlFormatter,
    },
    {
      dataField: "dept",
      text: "Dept Involved",
    },
    {
      dataField: "explanation",
      text: "Explanation",
      formatter: htmlFormatter,
    },
  ];

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then(function (result) {
      console.info("result", result);
      document.querySelector("#authCan").classList.add("hide");
      //document.querySelector("#noAuthCan").classList.add("hide");
      //if (isAuthenticated && result && result.COPA === "COPA") {
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1) {
        document.querySelector("#authCan").classList.remove("hide");
        document.querySelector("#noAuthCan").classList.add("hide");
      } else {
        document.querySelector("#authCan").classList.add("hide");
        document.querySelector("#noAuthCan").classList.remove("hide");
      }
    });
  }, [getAccessTokenSilently, user?.sub]);

  return items && items != undefined && items != "" ? (
    <div className="page-wrapper">
      {isAuthenticated ? (
        <div>
          <div id="noAuthCan">
            <SubHeaderWrapper className="my-6">
              <Container>
                <Row className="mb-6 align-items-center">
                  <Col lg="6">
                    <img src={featuredImage} alt="featured image" width="100%" />
                  </Col>
                  <Col lg="6" className="mt-4 mt-lg-0">
                    <h2>ACHC Specialty Pharmacy Accreditation</h2>
                    <p>
                      <a href="http://www.achc.org/" target="_self" rel="noopener">
                        ACHC
                      </a>
                      &nbsp;and COPA have partnered to provide members with a customized suite of
                      specialty pharmacy accreditation offerings, including discounts on educational
                      resources and accreditation programs. ACHC was the first accrediting body to
                      offer specialty pharmacy accreditation. By undergoing ACHC accreditation
                      pharmacies demonstrate their commitment to providing the highest quality
                      service by complying with stringent national regulations and industry best
                      practices.
                    </p>
                    <p>
                      ACHC’s Accreditation University offers a full suite of accreditation resources
                      to help pharmacies achieve and maintain accreditation.&nbsp;Click{" "}
                      <a href="http://accreditationuniversity.com/" target="_self" rel="noopener">
                        here
                      </a>{" "}
                      to learn more.
                    </p>
                  </Col>
                </Row>
              </Container>
            </SubHeaderWrapper>

            <CardsSection className="cards mb-6">
              <Container>
                <div className="cards-container ">
                  <Card className="py-6 px-3 " style={{ backgroundColor: "#9E9E9E" }}>
                    <h4 className="text-center mb-4">ACHC | COPA Partner Website</h4>
                    <div className="text-center">
                      <a href="https://cc.achc.org/copa" target="_self">
                        <Button outline color="light">
                          VISIT HERE
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <Card className="py-6 px-3 " style={{ backgroundColor: "#133D8D" }}>
                    <h4 className="text-center mb-4">Partnership Pricing</h4>
                    <div className="text-center">
                      <a
                        href="https://coapharmacy.com/wp-content/uploads/2022/10/COPA_Partnership-Flyer.pdf"
                        target="_blank"
                      >
                        <Button outline color="light">
                          SEE PRICING
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <Card className="py-6 px-3 " style={{ backgroundColor: "#4180BE" }}>
                    <h4 className="text-center mb-4">ACHC Standards</h4>
                    <div className="text-center">
                      <a
                        href="https://coapharmacy.com/wp-content/uploads/2022/12/ONC_2022-10-08.pdf"
                        target={"_blank"}
                      >
                        <Button outline color="light">
                          DOWNLOAD THE PDF
                        </Button>
                      </a>
                    </div>
                  </Card>
                </div>
              </Container>
            </CardsSection>
          </div>
          <div id="authCan" className="hide">
            <SubHeaderWrapper className="my-6">
              <Container>
                <Row className="mb-6 align-items-center">
                  <Col lg="6">
                    <img src={featuredImage} alt="featured image" width="100%" />
                  </Col>
                  <Col lg="6" className="mt-4 mt-lg-0">
                    <h2>ACHC Specialty Pharmacy Accreditation</h2>
                    <p>
                      <a href="http://www.achc.org/" target="_self" rel="noopener">
                        ACHC
                      </a>
                      &nbsp;and COPA have partnered to provide members with a customized suite of
                      specialty pharmacy accreditation offerings, including discounts on educational
                      resources and accreditation programs. ACHC was the first accrediting body to
                      offer specialty pharmacy accreditation. By undergoing ACHC accreditation
                      pharmacies demonstrate their commitment to providing the highest quality
                      service by complying with stringent national regulations and industry best
                      practices.
                    </p>
                    <p>
                      ACHC’s Accreditation University offers a full suite of accreditation resources
                      to help pharmacies achieve and maintain accreditation.&nbsp;Click{" "}
                      <a href="http://accreditationuniversity.com/" target="_self" rel="noopener">
                        here
                      </a>{" "}
                      to learn more.
                    </p>
                  </Col>
                </Row>
              </Container>
            </SubHeaderWrapper>

            <CardsSection className="cards mb-6">
              <Container>
                <div className="cards-container ">
                  <Card className="py-6 px-3 " style={{ backgroundColor: "#9E9E9E" }}>
                    <h4 className="text-center mb-4">ACHC | COPA Partner Website</h4>
                    <div className="text-center">
                      <a href="https://cc.achc.org/copa" target="_self">
                        <Button outline color="light">
                          VISIT HERE
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <Card className="py-6 px-3 " style={{ backgroundColor: "#133D8D" }}>
                    <h4 className="text-center mb-4">Partnership Pricing</h4>
                    <div className="text-center">
                      <a
                        href="https://coapharmacy.com/wp-content/uploads/2022/10/COPA_Partnership-Flyer.pdf"
                        target="_blank"
                      >
                        <Button outline color="light">
                          SEE PRICING
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <Card className="py-6 px-3 " style={{ backgroundColor: "#4180BE" }}>
                    <h4 className="text-center mb-4">ACHC Standards</h4>
                    <div className="text-center">
                      <a
                        href="https://coapharmacy.com/wp-content/uploads/2022/12/ONC_2022-10-08.pdf"
                        target={"_blank"}
                      >
                        <Button outline color="light">
                          DOWNLOAD THE PDF
                        </Button>
                      </a>
                    </div>
                  </Card>
                </div>
              </Container>
            </CardsSection>

            <AuditTools className="mb-6 py-6">
              <Container>
                <h3 className="text-center mb-5">AUDIT TOOLS</h3>
                <div className="btn-wrapper d-flex align-items-center justify-content-center flex-wrap">
                  <a
                    href="https://coapharmacy.com/wp-content/uploads/2022/03/ACHC-ContentsofSurvey.docx"
                    className="btn btn-outline-secondary text-uppercase "
                  >
                    ACHC CONTENTS SURVEY
                  </a>

                  <a
                    href="https://coapharmacy.com/wp-content/uploads/2022/03/PatientRecordAudit.docx"
                    className="btn btn-outline-secondary text-uppercase "
                  >
                    Patient Record Audit
                  </a>

                  <a
                    href="https://coapharmacy.com/wp-content/uploads/2022/03/PersonnelFilesAudit.docx"
                    className="btn btn-outline-secondary text-uppercase "
                  >
                    Personnel Files Audit
                  </a>

                  <a
                    href="https://coapharmacy.com/wp-content/uploads/2022/03/PotentialInterviewQuestions.docx"
                    className="btn btn-outline-secondary text-uppercase "
                  >
                    Potential Interview Questions
                  </a>

                  <a
                    href="https://coapharmacy.com/wp-content/uploads/2022/03/PlanofCorrection.docx"
                    className="btn btn-outline-secondary text-uppercase "
                  >
                    Plan of Correction
                  </a>
                </div>
              </Container>
            </AuditTools>
            <ACHCTable id="achc-table" className="mb-5">
              <Container>
                <h2 className="mb-5">ACHC Standards</h2>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={items}
                  columns={columns}
                  pagination={paginationFactory({ sizePerPage: 10 })}
                />
              </Container>
            </ACHCTable>

            <DiscountSection image="https://coapharmacy.com/wp-content/uploads/2022/03/ACHC-Banner-Background-with-text.png">
              <div className="left">
                <img
                  src="https://coapharmacy.com/wp-content/uploads/2022/03/ACHC-Banner-Background-with-text.png"
                  alt="discount"
                />
              </div>
              <div className="right pt-5 pb-5 px-3">
                <img
                  src="https://coapharmacy.com/wp-content/uploads/2022/03/achc-logo-200x200.png"
                  alt="achc"
                  width="75px"
                />
                <div className="discount-code">COPA DISCOUNT CODE</div>
                <img
                  src="https://coapharmacy.com/wp-content/uploads/2022/03/achc-code-2-600x251.png"
                  alt="coa586"
                  className="coa586"
                />

                <h4 className="mt-4">
                  <span>$2,000 OFF</span> SRX/SRX ONLY ACCREDITATION
                </h4>
                <h4>
                  <span>$50 OFF</span> WORKSHOP OR WORKBOOK
                </h4>

                <h6 className="mt-4">
                  <em>
                    $2,000 discount will be applied upon final payment and only one discount is
                    applicable per company for accreditation. $50 discount may be used for any COPA
                    member attending an ACHC workshop or any member purchasing the ACHC Guide to
                    Success digital workbook.
                  </em>
                </h6>
              </div>
            </DiscountSection>
          </div>
        </div>
      ) : (
        //Non COPA
        <div id="noAuthCan">
          <SubHeaderWrapper className="my-6">
            <Container>
              <Row className="mb-6 align-items-center">
                <Col lg="6">
                  <img src={featuredImage} alt="featured image" width="100%" />
                </Col>
                <Col lg="6" className="mt-4 mt-lg-0">
                  <h2>ACHC Specialty Pharmacy Accreditation</h2>
                  <p>
                    <a href="http://www.achc.org/" target="_self" rel="noopener">
                      ACHC
                    </a>
                    &nbsp;and COPA have partnered to provide members with a customized suite of
                    specialty pharmacy accreditation offerings, including discounts on educational
                    resources and accreditation programs. ACHC was the first accrediting body to
                    offer specialty pharmacy accreditation. By undergoing ACHC accreditation
                    pharmacies demonstrate their commitment to providing the highest quality service
                    by complying with stringent national regulations and industry best practices.
                  </p>
                  <p>
                    ACHC’s Accreditation University offers a full suite of accreditation resources
                    to help pharmacies achieve and maintain accreditation.&nbsp;Click{" "}
                    <a href="http://accreditationuniversity.com/" target="_self" rel="noopener">
                      here
                    </a>{" "}
                    to learn more.
                  </p>
                </Col>
              </Row>
            </Container>
          </SubHeaderWrapper>

          <CardsSection className="cards mb-6">
            <Container>
              <div className="cards-container ">
                <Card className="py-6 px-3 " style={{ backgroundColor: "#9E9E9E" }}>
                  <h4 className="text-center mb-4">ACHC | COPA Partner Website</h4>
                  <div className="text-center">
                    <a href="https://cc.achc.org/copa" target="_self">
                      <Button outline color="light">
                        VISIT HERE
                      </Button>
                    </a>
                  </div>
                </Card>

                <Card className="py-6 px-3 " style={{ backgroundColor: "#133D8D" }}>
                  <h4 className="text-center mb-4">Partnership Pricing</h4>
                  <div className="text-center">
                    <a
                      href="https://coapharmacy.com/wp-content/uploads/2022/10/COPA_Partnership-Flyer.pdf"
                      target="_blank"
                    >
                      <Button outline color="light">
                        SEE PRICING
                      </Button>
                    </a>
                  </div>
                </Card>

                <Card className="py-6 px-3 " style={{ backgroundColor: "#4180BE" }}>
                  <h4 className="text-center mb-4">ACHC Standards</h4>
                  <div className="text-center">
                    <a
                      href="https://coapharmacy.com/wp-content/uploads/2022/12/ONC_2022-10-08.pdf"
                      target={"_blank"}
                    >
                      <Button outline color="light">
                        DOWNLOAD THE PDF
                      </Button>
                    </a>
                  </div>
                </Card>
              </div>
            </Container>
          </CardsSection>
        </div>
        //Non COPA End
      )}
      <PostTeaser />
    </div>
  ) : (
    <p>...</p>
  );
};

export default Achc;

export const CardsSection = styled.section`
  h4 {
    font-weight: 800;
    color: #fff;
    font-size: 30px;
  }

  .card {
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col {
    border-radius: 0.25rem;
    overflow: hidden;
    position: relative;
  }

  .cards-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;

    & > div {
      flex: 1 1 33.333333%;
    }

    @media (max-width: 992px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      & > div {
        flex: 1 1 50%;
        max-width: calc(50% - 10px);
        align-self: stretch;
      }
    }

    @media (max-width: 768px) {
      & > div {
        max-width: 100%;
      }
    }
  }
`;

export const AuditTools = styled.section`
  background-color: #f2f2f2;

  .btn-wrapper {
    gap: 15px;
  }
`;

export const DiscountSection = styled.section`
  display: flex;
  background-color: #002238;

  .left {
    width: 40%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: top center;
    background-color: #002238;

    img {
      visibility: hidden;
      width: 100%;
    }
  }

  .right {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #002238;

    .discount-code {
      color: #133d8d;
      font-size: 22px;
      margin-top: 30px;
    }

    .coa586 {
      width: 100%;
      max-width: 425px;
    }

    h4 {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      span {
        font-weight: 700;
      }
    }

    h6 {
      color: #fff;
      font-size: 14px;
      max-width: 533px;
      margin: 0px auto;
    }
  }

  @media (max-width: 1300px) {
    .left {
      width: 60%;
    }

    .right {
      width: 40%;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    .left {
      width: 100%;
      background-color: #17496d;
    }

    .right {
      width: 100%;
    }
  }
`;

export const ACHCTable = styled.section`
  .react-bootstrap-table {
    overflow-x: auto;

    table {
      min-width: 1024px;

      th {
        &:nth-child(1) {
          width: 180px;
        }
        &:nth-child(3) {
          width: 100px;
        }
        &:nth-child(4) {
          width: 250px;
        }
      }
    }
  }

  .react-bootstrap-table-pagination-list {
    ul {
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    .react-bootstrap-table-pagination {
      text-align: center;

      .react-bootstrap-table-pagination-list {
        margin-top: 20px;
        ul {
          justify-content: center;
        }
      }
    }
  }
`;
