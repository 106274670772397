import React, { useEffect, useState }  from "react";
import { Container, Row } from "reactstrap";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Partdplan = () => {
const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
const [items, setItems] = useState(null);
const domain = "login.mycoa.io";
const { slug } = useParams();
const baseURL = "https://mycoa.io/api";

useEffect(() => {
  const getUserMetadata = async () => {
    try {
      const accessToken1 = await getAccessTokenSilently({
        audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();
      return user_metadata;
    } catch (e) {
      console.log(e.message);
    }
  };
  getUserMetadata().then(function (result) {
    console.info("result", result);
     document.querySelector("#authCan").classList.add("hide");
     document.querySelector("#noAuthCan").classList.add("hide");
    //if (isAuthenticated && result && result.COPA === "COPA") {
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1) {
      document.querySelector("#authCan").classList.remove("hide");
      document.querySelector("#noAuthCan").classList.add("hide");
    } else {
      document.querySelector("#authCan").classList.add("hide");
      document.querySelector("#noAuthCan").classList.remove("hide");
    }
  });
}, [getAccessTokenSilently, user?.sub]);

  return (
    <div className="page-wrapper my-6">
       {isAuthenticated ? (
        <div>
         <div id="noAuthCan"> 
         <Container>
        <p>
          Unfortunately, more and more practices report to us every day what can
          be characterized as nothing other than “horror stories” involving
          Medicare Part D patients seeking to deal with their Part D
          Prescription Drug Plan or the Pharmacy Benefits Manager (PBM) selected
          by the Plan Sponsor. This has included improper denial of access to
          providers of the patient’s choice, delay in delivery of needed
          medication by the PBM-owned pharmacy, mandatory use of the PBM-owned
          mail order pharmacy, dispensing errors, rude customer service,
          conflicting information being given to patients regarding benefits and
          coverage, and even improper access and use of patient personal health
          information (PHI).
        </p>
        <p>
          As Medicare Part D consumers, these patients have statutory rights to
          have grievances with their health plan and/or PBM. There are several
          options available to patients wishing to raise grievances.
          Importantly, member complaints and grievances directly impact Plans’
          ratings under the Medicare Star Rating System. Because of the
          financial incentives to maintain high Star Ratings, often times, Plans
          and PBMs are highly motivated to swiftly addressing patient complaints
          to avoid an impact on their overall Star Rating.
        </p>
      </Container>
        </div>
      <div id="authCan" className="hide">
      <Container>
        <p>
          Unfortunately, more and more practices report to us every day what can
          be characterized as nothing other than “horror stories” involving
          Medicare Part D patients seeking to deal with their Part D
          Prescription Drug Plan or the Pharmacy Benefits Manager (PBM) selected
          by the Plan Sponsor. This has included improper denial of access to
          providers of the patient’s choice, delay in delivery of needed
          medication by the PBM-owned pharmacy, mandatory use of the PBM-owned
          mail order pharmacy, dispensing errors, rude customer service,
          conflicting information being given to patients regarding benefits and
          coverage, and even improper access and use of patient personal health
          information (PHI).
        </p>
        <p>
          As Medicare Part D consumers, these patients have statutory rights to
          have grievances with their health plan and/or PBM. There are several
          options available to patients wishing to raise grievances.
          Importantly, member complaints and grievances directly impact Plans’
          ratings under the Medicare Star Rating System. Because of the
          financial incentives to maintain high Star Ratings, often times, Plans
          and PBMs are highly motivated to swiftly addressing patient complaints
          to avoid an impact on their overall Star Rating.
        </p>
        <p>
          Community oncology practices can play a lawful part in educating their
          patients about their rights under the Medicare Part D Program. Plans
          and program administrators cannot improve problems they don’t know
          about. To that end, COPA has provided helpful resources and guides for
          practices to provide to their Medicare Part D patients. Specifically,
          practices can download and provide to patients a sample complaint form
          as well as the instructions on the process. These materials will help
          educate patients on their rights, and provide guidance on how to
          assert those rights, if they so choose.
        </p>
        <p>
          In disseminating this material to patients, it is important that
          community oncology practices follow certain simple rules to ensure
          compliance with the Medicare Part D program.
        </p>
        <ul>
          <li>
            Communications with patients should be limited to informing patients
            of their rights and tools available to them.
          </li>
          <li>
            Practices must not to disparage any particular Part D Plan and/or
            PBM.
          </li>
          <li>
            Practices must not encourage patients to switch their Part D Plan
            and/or PBM.
          </li>
        </ul>

        <div className="d-flex align-items-center justify-content-center flex-wrap flex-colum flex-sm-row">
          <a
            href="#"
            className="btn btn-secondary mx-2 mb-3 mb-sm-0 text-uppercase"
          >
            Download sample form
          </a>
          <a
            className="btn btn-secondary mx-2 text-uppercase"
            href="https://coapharmacy2.com/wp-content/uploads/2022/05/COPA-COA_Provider-Guide-to-Medicare-Part-D-Patient-Complaints.pdf"
            target={"_blank"}
            download="download"
          >
            Download provider guide
          </a>
        </div>
      </Container>
      </div>
      </div>
       ):(
        <div id="noAuthCan"> 
          <Container>
        <p>
          Unfortunately, more and more practices report to us every day what can
          be characterized as nothing other than “horror stories” involving
          Medicare Part D patients seeking to deal with their Part D
          Prescription Drug Plan or the Pharmacy Benefits Manager (PBM) selected
          by the Plan Sponsor. This has included improper denial of access to
          providers of the patient’s choice, delay in delivery of needed
          medication by the PBM-owned pharmacy, mandatory use of the PBM-owned
          mail order pharmacy, dispensing errors, rude customer service,
          conflicting information being given to patients regarding benefits and
          coverage, and even improper access and use of patient personal health
          information (PHI).
        </p>
        <p>
          As Medicare Part D consumers, these patients have statutory rights to
          have grievances with their health plan and/or PBM. There are several
          options available to patients wishing to raise grievances.
          Importantly, member complaints and grievances directly impact Plans’
          ratings under the Medicare Star Rating System. Because of the
          financial incentives to maintain high Star Ratings, often times, Plans
          and PBMs are highly motivated to swiftly addressing patient complaints
          to avoid an impact on their overall Star Rating.
        </p>
      </Container>
        </div>
       )
}
<PostTeaser />
    </div>
  );
};

export default Partdplan;
