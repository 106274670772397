import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { SubHeaderWrapper } from "../../components/GlobalStyles";
import featuredImage from "../../assets/images/comment-letters-featured.jpg";

const CallsAndRecordings = () => {
  const cincopaRef = useRef();

  useEffect(() => {
    var script = document.createElement("script");
    script.async = true;
    script.src = "https://rtcdn.cincopa.com/libasync.js";
    var body = document.getElementsByTagName("head")[0];
    body.appendChild(script);
    script.onload = function (params) {
      window.cincopa.boot_gallery({
        _object: cincopaRef?.current,
        _fid: "A8NAJudNVtbr",
      });
    };
  }, []);
  return (
    <div className="page-wrapper">
      <SubHeaderWrapper className="my-6">
        <Container>
          <Row className="mb-6 align-items-center">
            <Col lg="6">
              <img src={featuredImage} alt="featured image" width="100%" />
            </Col>
            <Col lg="6" className="mt-4 mt-lg-0">
              <h2>Monthly Membership Calls & Recordings</h2>
              <p>
                Download Slide Deck for Implementation Strategies for USP
                Chapter by Clicking&nbsp;
                <a
                  href="https://coapharmacy.com/wp-content/uploads/2016/08/COA-USP-800-Implementation-Strategies-Presentation-10.11.2017.pdf"
                  target="_blank"
                  rel="noopener"
                  data-feathr-click-track="true"
                >
                  HERE
                </a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </SubHeaderWrapper>

      <Gallery className="mb-6">
        <Container>
          <div id="gallery" ref={cincopaRef}></div>
        </Container>
      </Gallery>
    </div>
  );
};

export default CallsAndRecordings;

export const Gallery = styled.section`
  #slideshowgallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
