import React, { useState } from "react";
import { Col, Container, Progress, Row } from "reactstrap";
import styled from "styled-components";
import { Rating } from "react-simple-star-rating";

const RatingSingle = () => {
  const [rating, setRating] = useState(3);
  const rating1 =
    "https://communityoncology.org/wp-content/uploads/2023/01/rating1.jpg";
  return (
    <div className="page-wrapper my-6">
      <Container>
        <Row>
          <Col lg="9">
            <PostItem>
              <div className="post-image">
                <img src={rating1} alt="image" />
              </div>
              <div className="post-meta">
                <div className="title text-primary">Crestor Pharma</div>
                <div className="ratings d-flex align-items-center mb-2">
                  <Rating initialValue={rating} size="22" className="mr-2" />{" "}
                  <span style={{ color: "#999" }}>(662)</span>
                </div>
                <div className="desc">
                  COA is a recognized leader and staunch supporter of
                  value-based care initiatives, which we believe can lower
                  costs.
                </div>
                <div className="category">Nebraska Oncology Specialists</div>
              </div>
            </PostItem>

            <h4 className="text-primary mt-5" style={{ fontWeight: "600" }}>
              Description
            </h4>
            <p>
              So how did the classical Latin become so incoherent? According to
              McClintock, a 15th century typesetter likely scrambled part of
              Cicero's De Finibus in order to provide placeholder text to mockup
              various fonts for a type specimen book.
            </p>
            <p>
              It's difficult to find examples of lorem ipsum in use before
              Letraset made it popular as a dummy text in the 1960s, although
              McClintock says he remembers coming across the lorem ipsum passage
              in a book of old metal type samples. So far he hasn't relocated
              where he once saw the passage, but the popularity of Cicero in the
              15th century supports the theory that the filler text has been
              used for centuries.
            </p>
          </Col>
          <Col lg="3" className="mt-4 mt-lg-0">
            <h4 className="text-primary" style={{ fontWeight: "600" }}>
              Ratings
            </h4>
            <div className="ratings d-flex align-items-center mb-2">
              <Rating
                initialValue={rating}
                readonly="true"
                size="22"
                className="mr-2"
              />{" "}
            </div>
            <RatingDetails className="rating-detail">
              <div className="item">
                <span>5 Stars</span>
                <Progress className="progress-bar-warning " value={65} />
                <span className="count">431</span>
              </div>

              <div className="item">
                <span>4 Stars</span>
                <Progress className="progress-bar-warning" value={30} />
                <span className="count">75</span>
              </div>

              <div className="item">
                <span>3 Stars</span>
                <Progress className="progress-bar-warning" value={18} />
                <span className="count">40</span>
              </div>

              <div className="item">
                <span>2 Stars</span>
                <Progress className="progress-bar-warning" value={15} />
                <span className="count">22</span>
              </div>

              <div className="item">
                <span>1 Stars</span>
                <Progress className="progress-bar-warning" value={60} />
                <span className="count">97</span>
              </div>
            </RatingDetails>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RatingSingle;

export const PostItem = styled.div`
  display: flex;
  gap: 20px;

  .post-image {
    flex: 0 1 150px;
    border-radius: 13px;
    position: relative;

    img {
      width: 100%;
    }
  }

  .post-meta {
    flex: 1 1;

    .title {
      display: block;
      font-size: 28px;
      font-weight: 600;
      font-family: "Montserrat";
    }

    .category {
      color: #999;
      margin-top: 7px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const RatingDetails = styled.div`
  .item {
    display: flex;
    align-items: center;
    gap: 15px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .progress {
      flex: 1 1;

      .progress-bar {
        background-color: var(--warning);
      }
    }
  }
`;
