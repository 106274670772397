import React, { useState, useEffect } from "react";
import {
  PageWrapper,
  TitleWrapper,
} from "../../assets/styled-components/components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  TabPane,
  NavLink,
  TabContent,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { showPartnerEventDetails } from "../../Store/Action/ProductAction.js";
import {DateConfig, TimeConfig, DateTimeConfig} from "../../dev-config/global"
const ProductDetail = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("1");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const store = useSelector((state) => state.products);
  const history = useHistory();
  // alert(id);
  // const product = {product_id:id};
  /*const productSlides = [
    "https://swiperjs.com/demos/images/nature-1.jpg",
    "https://swiperjs.com/demos/images/nature-2.jpg",
    "https://swiperjs.com/demos/images/nature-3.jpg",
    "https://swiperjs.com/demos/images/nature-4.jpg",
    "https://swiperjs.com/demos/images/nature-5.jpg",
  ];*/
  let data = store.details ? store.details : null;
  const [title, setTitle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState(null);
  const [registrationUrl, setRegistrationUrl] = useState(null);
  const [eventImage, setEventImage] = useState([]);

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const redirectURL = (url) => {
    return window.open(url, "_blank");
  };
  // const productInfo = () => {
  //  console.log('test',{product_id:id});
  //   return {product_id:id};
  // };

  const getCategoryname = (row) => {
    if (row.length > 0) {
      return row
        .map(function (e) {
          return e.category_name;
        })
        .join(", ");
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(showPartnerEventDetails(id));
    }
  }, [dispatch]);

  useEffect(() => {
    setTitle(data?.title);
    setDescription(data?.description);
    setRegistrationUrl(data?.registration_url);
    setStartDate(data?.start_date_time);
    setEndDate(data?.end_date_time);
    setEventImage(data?.event_image ? data?.event_image : []);
  }, [store]);

  return (
    <PageWrapper id="page-wrapper" className="pb-6">
      <TitleWrapper className="pt-6 mb-6">
        <Container>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div>
              <h3 className="text-white">Partner Event Detail</h3>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="partner-events-list">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Partner Event Name</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <Button
              color="light"
              size="sm"
              className=""
              // tag={Link}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </div>
        </Container>
      </TitleWrapper>
      <Container>
        <Row>
          <Col lg="5">
            <Card style={{ position: "sticky", top: "100px" }}>
              <CardBody>
                <img src={eventImage} alt="" width={"100%"} />{" "}
              </CardBody>
            </Card>
          </Col>
          <Col lg="7">
            <Card>
              <ProductCardBody>
                <h4>{title}</h4>

                <ProductMeta className="mb-2">
                  <div className="category mb-1">
                    <span className="title mr-1">Start Date:</span>
                    <span>{DateConfig(startDate)}</span>
                  </div>
                  <div className="manufacturer">
                    <span className="title mr-1">End Date:</span>
                    <span>{DateConfig(endDate)}</span>
                  </div>
                  <div className="category mb-1">
                    <span className="title mr-1">Start Time:</span>
                    <span>{TimeConfig(startDate)}</span>
                  </div>
                  <div className="manufacturer">
                    <span className="title mr-1">End Time:</span>
                    <span>{TimeConfig(endDate)}</span>
                  </div>

                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                </ProductMeta>
                <hr />
                <Button
                  color="primary"
                  size="sm"
                  tag="a"
                  target="_blank"
                  onClick={() => redirectURL(registrationUrl)}
                >
                  View Website
                </Button>
              </ProductCardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default ProductDetail;

export const ProductCardBody = styled(CardBody)`
  h4 {
    font-size: 1.4rem;
  }
`;

export const ReviewWrapper = styled.div`
  font-size: 0.856rem;
`;

export const ProductMeta = styled.div`
  font-size: 0.9rem;
  .title {
    font-weight: 600;
  }
`;

export const TabsWrapper = styled.div`
  .nav-tabs {
    border: unset;
    gap: 5px;
    a {
      font-size: 1.1rem;
      font-weight: 600;
      border-radius: 0.25rem;
      cursor: pointer;
      color: #787878;
      border: 1px solid #e2e2e2 !important;
      background-color: #f9f9f9;
      padding: 8px 16px;
      &.active {
        background-color: #dee8ff;
        color: var(--primary);
        border: 1px solid var(--primary);
      }
    }
  }
`;

export const DescriptionWrapper = styled.div`
  & div > :last-child {
    margin-bottom: 0;
  }
`;
