import axios from "axios";
export const createPartnerEvent = (data) => {
  return async (dispatch) => {
    await axios.post(`/partner-event/postData`, data).then((response) => {
      dispatch({
        type: "GET_ALL_PARTNER_EVENTS",
        data: response.data.data,
      });
    });
  };
};

export const updatePartnerEvent = (data) => {
  return async (dispatch) => {
    await axios.post(`/partner-event/updateData`, data).then((response) => {
      dispatch({
        type: "EDIT_PARTNER_EVENT",
        data: response.data.data,
      });
    });
  };
};

export const listPartnerEvents = () => {
  return async (dispatch) => {
    await axios.get(`/partner-event/list`).then((response) => {
      dispatch({
        type: "GET_ALL_PARTNER_EVENTS",
        data: response.data.data,
      });
    });
  };
};

export const listMyPartnerEvents = (userID) => {
  return async (dispatch) => {
    await axios.get(`/partner-event/mylist/${userID}`).then((response) => {
      dispatch({
        type: "GET_ALL_PARTNER_EVENTS",
        data: response.data.data,
      });
    });
  };
};

export const showPartnerEventDetails = (id) => {
  return async (dispatch) => {
    await axios
      .get(`/partner-event/getPartnerEventDetails/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_PARTNER_EVENT_DETAILS",
          data: response.data && response.data.data ? response.data.data : [],
        });
      });
  };
};
