import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";

import Select from "react-select";

import styled from "styled-components";

// images

import ResourceImage from "../../assets/images/resources.svg";
import EducationImage from "../../assets/images/education-publication.svg";
import NewsImage from "../../assets/images/news.svg";
import EventsImage from "../../assets/images/events.svg";
import { Download, ExternalLink } from "react-feather";

const Search = () => {
  const options = [
    { value: "Date Desc", label: "By Date Descending" },
    { value: "Date Asc", label: "By Date Ascending" },
    { value: "Title Asc", label: "By Title A-Z" },
    { value: "Title Desc", label: "By Title Z-A" },
  ];

  return (
    <SearchWrapper className="mb-5">
      <Container>
        <div className="search-inner">
          <Row className="search-box justify-content-center mb-4 pb-3">
            <Col sm="9" className="">
              <InputGroup>
                <Input placeholder="Enter Resource Name" />
                <InputGroupText>SEARCH</InputGroupText>
              </InputGroup>
            </Col>
          </Row>

          <CategoryWrapper className="mb-6 mb-lg-4">
            <Row className="align-items-center">
              <Col lg="9" className="mb-2 mb-lg-0">
                <CategorySelector className="ml-auto mr-auto ml-lg-0">
                  <div className="item">
                    <div className="item-inner">
                      <img src={ResourceImage} alt="#" />
                    </div>
                    <h6 className="text-center">RESOURCES</h6>
                  </div>
                  <div className="item">
                    <div className="item-inner">
                      <img src={EventsImage} alt="#" />
                    </div>
                    <h6 className="text-center">EVENTS</h6>
                  </div>
                  <div className="item">
                    <div className="item-inner">
                      <img src={NewsImage} alt="#" />
                    </div>
                    <h6 className="text-center">NEWS & UPDATES</h6>
                  </div>
                  <div className="item">
                    <div className="item-inner">
                      <img src={EducationImage} alt="#" />
                    </div>
                    <h6 className="text-center" style={{ lineHeight: "1.3" }}>
                      EDUCATION & PUBLICATIONS
                    </h6>
                  </div>
                </CategorySelector>
              </Col>
              <Col lg="3">
                <Select
                  options={options}
                  className="search-filter"
                  placeholder="Filter results"
                  styles={{
                    menuList: (styles) => ({
                      ...styles,
                      background: "#f3f3f3",
                    }),
                    option: (styles, { isFocused, isSelected }) => ({
                      ...styles,
                      background: isSelected ? "var(--primary)" : undefined,
                      zIndex: 1,
                    }),
                  }}
                />
              </Col>
            </Row>
          </CategoryWrapper>

          {/* <SearchResults>
            <h5 style={{ textTransform: "capitalize" }}>
              Search Results for: {query?.toLowerCase()?.replace(/-/g, " ")}
            </h5>

            <div className="articles mt-5">
              {posts?.length > 0 ? (
                <>
                  {posts?.map((post, index) => {
                    return (
                      <HorizontalArticle
                        // target={category ? `/${category}/${slug}/${post?.slug}` :`/${slug}/all/${post?.slug}`}
                        target={`${
                          process.env[
                            `REACT_APP_${
                              post?.initiative?.[0]
                                ? post?.initiative?.[0]
                                : "COA"
                            }`
                          ]
                        }/${post?.categories_slugs?.[0]}/${
                          post?.categories_slugs?.[1]
                        }/${post?.slug}`}
                        image={
                          post?.feature_image
                            ? post?.feature_image
                            : articlePlaceholder
                        }
                        title={post.title}
                        date={post.created_at}
                        excerpt={post.short_description}
                      />
                    );
                  })}
                  <div className="text-center">
                    <Button color="accent">LOAD MORE</Button>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  {fetching ? (
                    <Spinner
                      color="primary"
                      className="reload-spinner"
                      size="18"
                    />
                  ) : (
                    <Button color="accent">No Records Found</Button>
                  )}
                </div>
              )}
            </div>
          </SearchResults> */}
        </div>
      </Container>
    </SearchWrapper>
  );
};

export default Search;

export const SearchWrapper = styled.section`
  position: relative;

  .search-inner {
    position: relative;
    z-index: 2;
    background-color: #fff;
    border-radius: 30px;

    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    h3 {
      font-family: Montserrat;
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      margin-bottom: 40px;
    }

    .search-box {
      .input-group {
        input {
          border-width: 2px;
          height: 50px;
          border-color: var(--primary);
        }

        .input-group-text {
          background: var(--primary);
          padding-left: 30px;
          padding-right: 30px;
          color: #fff;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          font-weight: 600;
          border-width: 2px;
          border-left: unset;
          border-color: var(--primary);
          cursor: pointer;

          &:hover {
            background-color: var(--secondary);
            border-color: var(--secondary);
          }
        }
      }
    }
  }
`;

export const CategoryWrapper = styled.section`
  .search-filter {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    & > div {
      min-height: 43.59px;
    }

    &:focus-within {
      & * {
        box-shadow: unset;
        border-color: var(--primary);
      }
    }
  }
`;

export const CategorySelector = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-items: flex-start;
  max-width: 600px;

  &:hover .item:not(:hover) {
    filter: grayscale(1);
    opacity: 0.5;
  }

  .item {
    &.active {
      filter: grayscale(0) !important;
      opacity: 1 !important;
    }
    h6 {
      margin-top: 10px;
      font-size: 14px;
      font-family: var(--secondaryFont);
      font-weight: 600;
    }
    .item-inner {
      background-color: #d5e5ef;
      cursor: pointer;
      width: 80px;
      height: 80px;
      padding: 15px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid var(--primary);
      transition: 300ms;
      margin-left: auto;
      margin-right: auto;

      img {
        height: 40px;
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const SearchResults = styled.section`
  h5 {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .resource-item {
    &:not(:last-child) {
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #d1d3d4;
    }
    .icon {
      flex: 0 0 100px;
    }

    .resource-details {
      flex: 2;

      h5 {
        margin-bottom: 5px;
        line-height: 1.3;
        a {
          font-family: "Montserrat";
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;
          color: var(--accent) !important;
        }
      }

      .category,
      .sub-category,
      .date {
        color: #4180be;

        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: 0.75px;
      }

      .excerpt {
        color: #101c3b;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
      }
      .link-icons {
        color: var(--accent);
      }

      .meta {
        gap: 10px 20px;
        .category,
        .sub-category {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            right: -9px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 12px;
            background-color: var(--link);
          }
        }
      }
    }

    .featured-image {
      flex: 0 0 220px;

      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center center;
        aspect-ratio: 16/10;
      }
    }

    @media (max-width: 860px) {
      .resource-details {
        margin-right: 0 !important;
      }
      .featured-image {
        flex: 1 1 100% !important;
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0 !important;
        /* margin-top: 15px; */
      }
    }
  }
`;
